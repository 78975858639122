
const SNBOOKMARKS = {
	onReady: () => {
        SNBOOKMARKS.initAddBookmark();
	},
				
				
				
    initAddBookmark: () => {
        const triggerAddBookmark = document.querySelectorAll('.trigger-bookmark');

        triggerAddBookmark.forEach( (trigger) => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();

                SNBOOKMARKS.context = trigger;

                if ( SNBOOKMARKS.context.classList.contains('bookmark-add') )
                    SNBOOKMARKS.addBookmark();
                
                else if ( SNBOOKMARKS.context.classList.contains('bookmark-remove') )
                    SNBOOKMARKS.removeBookmark();
            });
        });
    },



    addBookmark: () => {
        let wpid    = SNBOOKMARKS.context.getAttribute('data-wpid');
        let eventID = SNBOOKMARKS.context.getAttribute('data-eventID');

        const body = new FormData();
        body.append("sn-fields[wpid]", wpid);
        body.append("sn-fields[eventID]", eventID);

        fetch(snJS.restUrl + '/bookmark/add', {
            method: 'POST',
            body,
            credentials: "same-origin",
            headers: {
              "X-WP-Nonce": snJS.nonce,
            },
        })
        .then((response) => response.json())
        .then((response) => {
            // console.log(response);

           document.querySelectorAll('.bookmark-add').forEach( (el) => {
                if ( el.getAttribute('data-eventID') == eventID ) {
                    el.classList.add('bookmark-remove', 'is-info');
                    el.classList.remove('bookmark-add', 'is-primary', 'is-outlined');
                    el.querySelector('span').textContent = 'vorgemerkt';
                }
            });
        })
        .catch((error) => {
            Bulma().alert({
                type: 'danger',
                title: 'Fehler',
                body: 'Bei Ihrer Anfrage ist eine Fehler aufgetreten. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support.' + error,
                confirm: {
                    label: 'OK',
                }
            });
        })
        ;
    },



    removeBookmark: () => {
        let wpid    = SNBOOKMARKS.context.getAttribute('data-wpid');
        let eventID = SNBOOKMARKS.context.getAttribute('data-eventID');

        const body = new FormData();
        body.append("sn-fields[wpid]", wpid);
        body.append("sn-fields[eventID]", eventID);

        fetch(snJS.restUrl + '/bookmark/remove', {
            method: 'POST',
            body,
            credentials: "same-origin",
            headers: {
              "X-WP-Nonce": snJS.nonce,
            },
        })
        .then((response) => response.json())
        .then((response) => {
            // console.log(response);

           document.querySelectorAll('.bookmark-remove').forEach( (el) => {
                if ( el.getAttribute('data-eventID') == eventID ) {
                    if (el.classList.contains('bookmark-remove-item') ) { // Bookmark items list
                        if ( document.querySelectorAll('.bookmark-item-removeable').length == 1 )
                            el.closest('.bookmark-item-removeable').parentElement. insertAdjacentHTML("beforeend", '<p>bisher keine gemerkten Veranstaltungen</p>');

                        el.closest('.bookmark-item-removeable').remove();

                    } else { // event item
                        el.classList.add('bookmark-add', 'is-primary', 'is-outlined');
                        el.classList.remove('bookmark-remove', 'is-info');
                        el.querySelector('span').textContent = 'vormerken';

                    }
                }
            });
        })
        .catch((error) => {
            Bulma().alert({
                type: 'danger',
                title: 'Fehler',
                body: 'Bei Ihrer Anfrage ist eine Fehler aufgetreten. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support.' + error,
                confirm: {
                    label: 'OK',
                }
            });
        })
        ;
    }
}



SNBOOKMARKS.onReady();
